import { KeycloakOnLoad } from 'keycloak-js';

export const environment = {
  production: true,
  api: 'https://www.mijnsportvisserij.nl/svnl/api',
  keycloak: {
    url: 'https://www.mijnsportvisserij.nl/auth',
    realm: 'Svnl',
    clientId: 'funnel',
    initOptions: {
      onLoad: 'check-sso' as KeycloakOnLoad,
      silentCheckSsoRedirectUri: '',
      redirectUri: '',
    },
  },
  endOfYearWarning: {
    enabled: false,
    currentYear: 2024,
    nextYear: 2024,
    validYear: 2024,
  },
  buckaroo: {
    applepay: {
      displayName: 'Sportvisserij Nederland',
      domainName: 'www.vispas.nl',
      merchantIdentifier: 'BA5D2B33D04F488FB741F48248CDEAC4',
      label: 'Sportvisserij Nederland Webshop',
    },
  },
  portal: {
    login: 'https://www.mijnsportvisserij.nl',
    permits:'https://www.mijnsportvisserij.nl/permits',
    coupling: 'https://www.mijnsportvisserij.nl/coupling/cardnumber',
  },
  analytics: {
    google: 'UA-3799306-27',
    hotjar: '231909',
    adftrack: '1222980',
    gtm: 'GTM-TR6ZW4D',
  },
  maintenance: false,
  showDebugFooter: false,
};
